.market-news-page {

  background: #eeeeee;

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #4e6ef1;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  h1 {
    margin-bottom: 20px;
    text-align: left;
    margin-left: 100px;
    margin-top: 0px;
    padding-top: 50px;
    padding-bottom: 0px;
  }

  a.title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content {
    text-align: center;
    max-width: 1500px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;

    .news-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }

    .news-card {
      background: white;
      border-radius: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      width: calc(23.333% - 20px);
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }

      .news-info {
        padding: 10px;

        h2 {
          font-size: 1.2em;
          margin: 10px 0;
          a {
            color: #333;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        p {
          margin: 5px 0;
          color: #666;
          font-size: 0.9em;
        }
      }
    }
  }

  @media (max-width: 768px) {
   

    .content {
      padding-left: 70px;
      .news-card {
        width: calc(80% - 20px);
      }
    }
  }

  @media (max-width: 480px) {
    .content {
      padding-left: 70px;
      .news-grid {
        display: block;
      }

      .news-card {
        width: calc(80%);
      }
    }
  }
}
