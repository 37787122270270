.wallet-page {
    padding-left: 60px;
    height: 100vh;
    background: #eeeeee;
    h1 {
        margin-top: 0px;
        padding: 50px;
    }
  }
  
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f9f9f9;
  
    form {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
      h1 {
        margin-bottom: 20px;
      }
  
      input {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
      }
  
      button {
        padding: 10px;
        border: none;
        background-color: #007bff;
        color: white;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  