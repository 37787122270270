.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Adjust as needed

  .spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3b54c1; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}