.community-page {
  padding-left: 60px;
  height: 100vh;
  background: #eeeeee;

  h1 {
    margin-top: 0px;
    padding: 50px;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: #eeeeee;
    padding-left: 60px;

    @media (max-width: 600px) {
      padding: 10px;
    }

    .stop-button {
      background-color: #007bff;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      border: none;
      color: white;
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      animation: fadeIn 1s ease-in-out;

      &:hover {
        background-color: #0056b3;
      }
    }

    .voice-button {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: linear-gradient(35deg, #2f4191, #4E6EF1);
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      box-shadow: 0 0 10px rgba(59, 86, 211, 0.6), 0 0 30px rgba(59, 86, 211, 0.4);
      position: relative;
      overflow: hidden;
      animation: popEffect 2s ease;

      &:hover {
        box-shadow: 0 0 20px rgba(59, 86, 211, 0.8), 0 0 40px rgba(59, 86, 211, 0.6);
      }

      &:active {
        background-color: #2a3f9b;
      }

      .eye {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: white;
        left: 20%;
        top: 30%;
        pointer-events: none;
        overflow: hidden;
      }

      .eye + .eye {
        left: 50%;
      }

      .pupil {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: black;
        left: 60%;
        top: 70%;
        margin-left: -10px;
        margin-top: -10px;
        transform: translate(-50%, -50%);
        animation: movePupil 12s infinite;
      }

      .eyelid {
        width: 40px;
        height: 20px;
        background-color: #3b56d3;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0% / 100%;
        animation: blink 4s infinite;
        transition: all 0.5s ease;
      }

      .eye + .eye .eyelid {
        animation-delay: 0.05s;
      }

      .smirk {
        position: absolute;
        width: 30px;
        height: 5px;
        border-radius: 20%;
        background-color: white;
        top: 85%;
        left: 50%;
        margin-left: -15px;
        margin-top: -5px;
        transform: rotate(10deg);
      }

      .smile {
        position: absolute;
        width: 30px;
        height: 50px;
        border: 5px solid transparent;
        border-top-color: white;
        border-radius: 50px;
        top: 52%;
        left: 80%;
        margin-left: -50px;
        margin-top: -25px;
        transform: rotate(190deg);
      }
    }

    .ai-click {
      margin-bottom: 0px;
      font-weight: 400;
    }

    .mic-required {
      font-size: 0.9rem;
      margin-top: -1px;
      font-weight: 600;
      color: #6b6b6b;
      display: flex;
      align-items: center;

      i {
        font-size: 19px;
      }
    }

    .wait-message, .start-talking-message {
       font-weight: 600;
    }

    .voice-button-text {
      margin-top: 10px;
      font-size: 14px;
      color: #555;
      text-align: center;
      font-weight: 600;
      background: white;
      padding: 5px 10px;
      border-radius: 10px;
      border: 1px solid #ececec;
      margin-bottom: 20px;
    }

    .suggestion-cards-wrapper {
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;
      margin-bottom: 30px;

      @media (max-width: 1220px) {
        width: 100%;
      }
    
      .scroll-button {
        background-color: #4E6EF1;
        border: none;
        color: white;
        cursor: pointer;
        padding: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 10;
    
        &.prev-button {
          left: 0;
        }
    
        &.next-button {
          right: 0;
        }
    
        &:hover {
          background-color: #3b56d3;
        }
      }
    
      .suggestion-cards {
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
        padding: 20px 0;
        max-width: 1400px;
        animation: fadeIn 1s ease-in-out;

        @media (min-width: 1430px) {
          justify-content: center;
        }
      
        &::-webkit-scrollbar {
          display: none;
        }
      
        .card {
          min-width: 200px; /* Adjust as needed */
          max-width: 200px;
          background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.3) 100%);          border-radius: 12px;
          padding: 20px;
          border: 1px solid #e7e7e7;
          margin-right: 20px;
          position: relative;
          transition: transform 0.3s ease;
      
          &:hover {
            transform: translateY(-10px);
          }
      
          p {
            margin: 0 0 30px 0;
            font-size: 16px;
            color: #333;
            font-weight: 500;
          }
      
          i.material-icons {
            font-size: 24px;
            color: #4e6ff1d2;
            position: absolute;
            bottom: 10px; /* Align icon to the bottom */
            right: 10px;  /* Align icon to the right */
          }
        }
      }
      
    }
    

    .content-message {
      max-width: 980px;
      background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);          
      border-radius: 12px;
      padding: 20px 25px;
      border-radius: 10px;
      margin-top: 30px;
      margin-right: 15px;
      font-size: 18px;
      line-height: 1.6;
      color: #333;
      position: relative;
      animation: fadeIn 1s ease;

      &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50px;
        border-width: 15px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }

      p {
        margin: 0;
        font-size: 1rem;
        color: #333;
        text-align: left;
      }
    }
  }
}

@keyframes blink {
  0%, 90% {
    height: 20px;
  }
  95% {
    height: 40px;
  }
  100% {
    height: 20px;
  }
}

@keyframes movePupil {
  0% {
    transform: translate(-50%, -50%) translate(5px, 5px);
  }
  25% {
    transform: translate(-50%, -50%) translate(7px, 7px);
  }
  50% {
    transform: translate(-50%, -50%) translate(-5px, 5px);
  }
  75% {
    transform: translate(-50%, -50%) translate(7px, 7px);
  }
  100% {
    transform: translate(-50%, -50%) translate(5px, 5px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
