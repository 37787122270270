@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@keyframes colorChange {
  0% {
    color: black;
  }
  50% {
    color: rgb(110, 95, 11);
  }
  100% {
    color: #4e6ef1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.gradient-text {
  background: linear-gradient(270deg, #0000ff, #4e6ef1, #b8500b, #0000ff);
  background-size: 600% 600%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 30s ease infinite;
  display: inline-block;
  font-size: 3em; /* Adjust the size as needed */
  opacity: 0.8;
  margin: 0px;
  animation: fadeInUp 1s ease-in-out forwards;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-text {
  display: inline-block;
  .letter {
    display: inline-block;
    animation: colorChange 25s ease-in-out infinite;
    animation-fill-mode: forwards;
    animation-delay: calc(0.1s * var(--char-index));
  }
  .letter {
    display: inline-block;
  }
  .word {
    display: inline-block;
  }
  .letter.space {
    width: 0.25em;
  }
}

.home {
  padding: 5% 50px 0px calc(10% + 57px);
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .home-header {
    max-width: 800px;
    animation: fadeInUp 1s ease-in-out forwards;

    .pagination {
      display: flex;
      justify-content: flex-start;

      .next,
      .previous {
        display: flex;
        align-items: center;
        color: white;
        background: #4e6ef1;
        border: none;
        border-radius: 3px;
        padding: 5px 10px;
        cursor: pointer;
        margin: 15px 5px 15px 0px;
        &:hover {
          background-color: #3b54c1;
        }
      }
    }

    .subtext {
      font-size: 1rem;
      margin-bottom: 20px;
      margin-top: 0px;
      color: #666;
      font-weight: 400;
      width: 500px;
      animation: fadeInUp 1s ease-in-out forwards;
    }

    h1:first-of-type {
      margin-bottom: -10px;
    }

    h1 {
      font-size: 3.3em;
      color: #333;
      letter-spacing: 0px;
    }



    .home-button {
      padding: 10px 20px;
      font-size: 1.2em;
      color: white;
      background-color: #0073b1;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #005f8c;
      }
    }
  }

  .card {
    background-color: #fff;
    border-radius: 8px;
    padding: 25px 30px 20px 30px;
    box-shadow: 0 2px 5px 2px rgba(138, 138, 138, 0.1);
    transition: background-color 0.3s;
    width: 100%;
    max-width: 250px;
    margin-bottom: 0px;
    min-width: 200px;
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Initial position for the slide-in effect */
    animation: slideIn 2.6s ease-in-out forwards;
  
    &:hover {
      background-color: #000;
      color: #fff;
  
      .card-title,
      .card-subtitle,
      .progress-bar,
      .card-button {
        color: #fff;
      }
    }
  
    .card-header {
      display: flex;
      flex-direction: column;
  
      img {
        border-radius: 8px;
        margin-right: 16px;
        width: 40px;
        margin-bottom: 10px;
        color: #4e6ef1;
      }
  
      .card-maintitle {
        margin-bottom: 30px;
        display: block;
      }
  
      .card-title {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: left;
      }
  
      .checkmark {
        margin-right: 3px;
        font-size: 16px;
        color: #999;
      }
  
      .card-subtitle {
        display: flex;
        font-size: 0.9rem;
        color: #555;
        text-align: left;
        font-weight: 400;
        align-items: center;
        white-space: nowrap;
      }
    }
  
    .card-progress {
      margin: 16px 0;
      height: 8px;
      background-color: #e0e0e0;
      border-radius: 4px;
  
      .progress-bar {
        height: 100%;
        background-color: #4e6ef1;
        border-radius: 4px;
      }
    }
  
    .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .card-button {
        margin-top: 50px;
        background-color: #333333;
        color: #fff;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #3b54c1;
        }
      }
    }
  }
}



.auth-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    margin: 10px 0;
    padding: 10px;
    width: 200px;
  }

  button {
    margin: 10px 0;
    padding: 10px;
    cursor: pointer;
  }

  p {
    color: red;
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 850px) {
  .home {
    padding: 0 10px 0 calc(57px + 10px);

    .home-header {
      padding: 10px;
      margin-top: 20%;

      .subtext {
        font-size: 0.9rem;
        width: 80%;
      }

      h1.sub-title {
        line-height: 30px;
        height: 75px;
      }

      h1 {
        font-size: 2em;
      }

      p {
        font-size: 0.9em;
      }
    }

    .card-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      justify-content: center;
      width: 100%;
    }

    .card {
      max-width: 100%;
      min-width: 0;
      width: calc(80% - 10px);
      margin-bottom: 10px;
      padding-left: 15px;

      .card-header {
        img {
          width: 40px;
        }
      }

      .card-title {
        font-size: 1rem;
      }

      .card-subtitle {
        font-size: 0.8rem;
      }

      .card-button {
        padding: 6px 12px;
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 640px) {
  .home {
    .card-container {
      display: block;
      gap: 15px;
      justify-content: center;
      width: 100%;
    }

    .home-header {
      h1 {
        font-size: 1.9em;
      }
    }

    .submit-card {
      max-width: 100%;
      min-width: 0;
      width: auto;
      padding-top: 5px;
      border-radius: 2px;
      margin-bottom: 10px;
      border-right: 15px solid #4e6ef1;

      .card-header {
        img {
          width: 40px;
        }
      }

      .card-title {
        font-size: 1rem;
      }

      .card-subtitle {
        font-size: 0.8rem;
      }

      .card-button {
        padding: 6px 12px;
        font-size: 0.9rem;
      }
    }

    .card {
      max-width: 100%;
      min-width: 0;
      width: 80%;
      border-radius: 2px;
      margin-bottom: 10px;
      border-right: 15px solid #4e6ef1;

      .card-header {
        img {
          width: 40px;
        }
      }

      .card-title {
        font-size: 1rem;
      }

      .card-subtitle {
        font-size: 0.8rem;
      }

      .card-button {
        padding: 6px 12px;
        font-size: 0.9rem;
      }
    }
  }
}
