@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body, html {
  font-family: 'Poppins', sans-serif;
}

.App {
    .connect-account-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: fixed;
        z-index: 9999;
    }
}


// Add any other global styles here

// Add any other global styles here
