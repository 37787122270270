.private-key-modal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  margin: auto;
  outline: none;

  h2 {
    margin-top: 0;
  }

  p {
    margin-bottom: 15px;
    word-wrap: break-word;
  }

  button {
    background-color: #4e6ef1;
    color: white;
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    &:hover {
      background-color: #3b5de7;
    }
  }

  .disabled-button {
    background-color: #e2e2e2;
    color: #555;

    &:hover {
      background-color: #ccc;
    }
  }

  button.private-key {
    background-color: #e2e2e2;
    color: #555;
    font-weight: 600;
  }
}

.private-key-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solana {
  margin-left: 10px;
}

@media (max-width: 500px) {
  .private-key-modal {
    width: 300px !important;
  }

  .wallet-page {
    h1 {
      padding-left: 20px !important;
    }
  }

  .solana {
    margin-left: 0px;
  }

  .crypto-chat-wallet .button-wallet {
    display: block !important;
  }

  .crypto-chat-wallet {
    padding: 10px;

    .wallet-page {
      h1 {
        padding-left: 10px;
      }
    }

    .center-container {
      padding: 20px 10px !important;
      flex-direction: column;
    }

    .wallet-content {
      .wallet-btns {
        flex-direction: column; // Stack the wallet sections vertically
      }

      .wallet-section {
        cursor: pointer;
        max-width: 100%; // Make wallet sections full-width
        margin-bottom: 20px; // Add spacing between stacked wallet sections

        h3 {
          font-size: 1rem; // Reduce heading size on mobile
        }

        p {
          font-size: 0.8rem; // Reduce paragraph text size on mobile
        }

        button.button-wallet {
          padding: 8px 15px; // Adjust button padding for mobile
          font-size: 0.8rem; // Reduce button text size
        }
      }
    }

    .disclaimer h4 {
      font-size: 0.8rem; // Adjust disclaimer text size
      width: 100%; // Make disclaimer full-width
    }
  }
}

.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .small-modal {
    background: #fff;
    padding: 20px 20px 20px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;

    h4 {
      margin-right: 0;
      text-align: left;
      margin-top: 0;
    }

    .modal-buttons {
      display: flex;
      margin-top: 20px;
      .yes {
        background: #4e6ef1;
        color: #fff;
        margin-right: 10px;
      }
    }

    .modal-button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    .modal-button.cancel {
      background: #f5f5f5;
    }

    .modal-button:hover {
      background: #3b5de7;
      color: #fff;
    }

    .modal-button.cancel:hover {
      background: #ddd;
      color: #555;

    }
  }
}

.crypto-chat-wallet {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;

  .education-content {
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 25px 30px;
    border-radius: 8px;
    margin: 20px 0;

    h3 {
      margin-top: 0;
      margin-bottom: 15px;
    }

    .button {
      background-color: #4e6ef1;
      color: white;
      padding: 10px 15px 10px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin: 15px;
      transition: background-color 0.3s ease;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: block;
        margin-right: 4px;
      }

      &:hover {
        background-color: #3b5de7;
      }
    }

    .rerun-button {
      background-color: #4e6ef1;
      color: white;
      padding: 10px 15px 10px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin: 15px;
      transition: background-color 0.3s ease;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: block;
        margin-right: 4px;
      }

      &:hover {
        background-color: #3b5de7;
      }
    }

    .education-box {
      text-align: left;
    }
  }

  .center-container {
    display: flex;
    margin-bottom: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px 2px #e6e6e6;
    width: max-content;
    flex-direction: row;
  }

  h4 {
    color: #333;
    text-align: center;
    margin-right: 15px;
  }

  .wallet-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    .wallet-btns {
      display: flex;
      flex-direction: row;
    }
  }

  .ethereum {
    margin-right: 10px;
  }

  .wallet-section {
    margin-bottom: 40px;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 5px 2px #e6e6e6;
    max-width: 340px;
    cursor: pointer;

    &.selected {
      background-color: #555; /* Darker grey */
      color: white;
    }

    p {
      color: #616161;
      font-size: 0.9rem;
    }
  }

  .chat-messages {
    border: 1px solid #ddd;
    padding: 10px;
    height: 300px;
    overflow-y: scroll;
    background-color: #fff;

    .message {
      margin-bottom: 10px;

      strong {
        color: #0073b1;
      }
    }
  }

  .input-group {
    margin-top: 10px;
    display: flex;

    input {
      flex: 1;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-right: 10px;
    }

    button {
      padding: 10px 20px;
      background-color: #0073b1;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #005f8c;
      }
    }
  }

  .wallet-info {
    margin-top: 20px;

    p {
      margin: 5px 0;
      word-break: break-all;
    }
  }

  .selectedWallet,
  .selected {
    position: relative;
    border: 2px solid #4caf50; /* Green border for selected */
    padding: 10px;
    border-radius: 5px;
    background-color: #f7fff7; /* Light background for selected */
  }

  .checkmark {
    position: absolute;
    top: -10px;
    right: -8px;
    background-color: #4caf50;
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 10px;
  }

  .selected-wallet-container {
    h2 {
      font-size: 1.2rem;
    }
  }

  .disclaimer {
    h4 {
      margin-top: 0px;
      font-size: 0.9rem;
      color: #777;
      text-align: left;
      font-weight: 500;
      width: 80%;
    }
  }

  .card-button {
    margin-top: 20px;
    background-color: #4e6ef1;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.9rem;

    &:hover {
      background-color: #3b54c1;
    }
  }

  .button {
    background-color: #4e6ef1;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;

    &:hover {
      background-color: #3b5de7;
    }
  }

  .button-wallet {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 10px 10px 0px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.selected {
      background-color: #555; /* Darker grey */
      color: white;
    }

    &.selectedWallet {
      background-color: #555; /* Darker grey */
      color: white;
    }

    &:hover {
      background-color: #ddd; /* Light grey on hover */
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    img {
      margin-left: 8px;
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #e2e2e2;
    }
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #0073b1;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  .wallet-address {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media (max-width: 715px) {
  .crypto-chat-wallet {
    .wallet-content {
      flex-direction: column;

      .wallet-btns {
        flex-direction: column;
        align-items: stretch;

        .wallet-section {
          max-width: 100%;
          margin-bottom: 20px;
          padding: 15px;
        }
      }

      .disclaimer h4 {
        font-size: 0.8rem;
        text-align: left;
      }
    }

    .center-container {
      flex-direction: column;
      align-items: center;
    }

    .button-wallet {
      padding: 8px 15px;
      font-size: 0.85rem;
    }

    h4 {
      font-size: 1rem;
    }
  }
}
