.coins-page {
  padding: 20px 20px 20px 2%;
  margin-left: 57px; // Adjust for left bar
  background: #eeeeee;
  height: 100%;

 
  .buttons-container {
    justify-content: flex-start;
    margin-bottom: 20px;
    border-radius: 12px;
    padding: 15px 0px;
    width: 230px;
    flex-direction: row;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      width: 100px;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-right: 10px;
      margin-bottom: 10px;
      border: none;
      padding: 10px;
      background: white;

      &:hover {
        background: #f0f0f0;
        border-radius: 10px;
      }

      .icon {
        margin-bottom: 5px;
        border-radius: 50%;
        background-color: #f7f7f7;
        padding: 15px;
        margin-bottom: 20px;
      }

      .label {
        font-size: 12px;
        color: #333333;
        text-align: center;
        font-weight: 600;
        padding-bottom: 5px;
      }
    }
  }

  @keyframes positiveBackgroundAnimation {
    0%, 100% {
      background: rgb(243, 255, 243);
    }
    50% {
      background: white;
    }
  }
  
  @keyframes negativeBackgroundAnimation {
    0%, 100% {
      background: rgb(255, 244, 244);
    }
    50% {
      background: white;
    }
  }

  
  .positive-card {
    background: rgb(224, 255, 224);
    animation: positiveBackgroundAnimation 7s infinite ease-in-out;

  }

  .negative-card {
    background: rgb(255, 217, 217);
    animation: negativeBackgroundAnimation 7s infinite ease-in-out;
  }

  .coin-container {
    display: flex;
  }

  .coins-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .coin-card {
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0 2px 5px 2px rgba(138, 138, 138, 0.1);
      padding: 20px;
      width: calc(33.333% - 20px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      transition: all 0.5s ease-in-out;

      strong {
        font-weight: 600;
      }

      &:hover {
        background: #f0f0f0;
      }
 
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
      }

      .coin-name {
        font-size: 1.2em;
        margin-bottom: 10px;
        font-weight: 700;
      }

      .coin-price,
      .coin-market-cap,
      .coin-volume,
      .coin-change {
        margin-bottom: 5px;
      }

      .coin-change.positive {
        color: green;
      }

      .coin-change.negative {
        color: red;
      }
    }
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; // Adjust as needed to center spinner vertically
  }

   
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #4e6ef1;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

@media (max-width: 600px) {
  .coins-page {
    height: 100%;
    .coin-container {
      display: flex;
    }
    .buttons-container {
      flex-direction: column;
      width: 125px;
      button {
        margin-top: 5px;
        width: 105px;
      }
    }

    h1 {
      white-space: nowrap;
    }

    .coins-grid {
      margin-top: 5%;
      .coin-card {
        width: 100%;
      }
    }
  }
}
